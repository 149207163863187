<template>
  <el-dialog v-dialogDrag title="岗位介绍" append-to-body :visible.sync="dialogVisible" :before-close="handleClose"
    :close-on-press-escape="false" :close-on-click-modal="false">
    <el-row>
      <el-col :span="24">
          <tinymce @Tinymce="Tinymce" :checkcon="checkcon"></tinymce>
      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="deteRmine()">确 定</el-button>
      <el-button @click="cancel">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import tinymce from '@/components/Tinymce/index.vue';

  export default {
    props:{
      gejsshowval:String,
    },
    components: {
      tinymce
    },
    data() {
      return {
        dataval: '',
        dialogVisible:true,
        checkcon: '',
      }
    },

    mounted() {
        this.checkcon = this.gejsshowval;
    },
    methods: {
      Tinymce(data) {
        this.dataval = data.tinymcedata;
       //this.$emit('gejsshowdata',this.dataval)
      },
      handleClose(){
        this.dialogVisible = false;
       this.$emit('gejsshowdataClose',this.dataval)
      },
      cancel(){
        this.dialogVisible = false;
       this.$emit('gejsshowdataClose',this.dataval)
      },
      deteRmine(){
        this.dialogVisible = false;
        this.$emit('gejsshowdata',this.dataval)
      },
    }


  }
</script>

<style scoped>
 ::v-deep .el-dialog {
    width: 760px !important;
    border-radius: 8px;
    overflow: hidden;
  }

</style>
