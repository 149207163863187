<template>
	<div>
		<editor id='tinymce' v-model='tinymceHtml' :init='init'></editor>
		<!-- <p @click="delFile">删除</p> -->
		<!-- 预览功能，如不需要，可将下面一行代码注释删除 -->
		<!--<div v-html='tinymceHtml'></div>-->
		<!-- "@tinymce/tinymce-vue": "^3.0.1",-->
		<!--"tinymce": "^4.5.10",-->
	</div>
</template>
<script>
	//import { uploadfile ,delfile} from "@/api/api";

	import tinymce from 'tinymce/tinymce'
	import 'tinymce/themes/modern/theme'
	import Editor from '@tinymce/tinymce-vue'
	import 'tinymce/plugins/image'
	import 'tinymce/plugins/link'
	import 'tinymce/plugins/code'
	import 'tinymce/plugins/table'
	import 'tinymce/plugins/lists'
	import 'tinymce/plugins/contextmenu'
	import 'tinymce/plugins/wordcount'
	import 'tinymce/plugins/colorpicker'
	import 'tinymce/plugins/textcolor'
	import 'tinymce/plugins/charmap';
	import 'tinymce/plugins/print';

	export default {
		name: 'EditorTinymce',
		props: ['checkcon'],
		components: {
			Editor
		},
		data() {
			return {
				tinymceHtml: '',
				picUrl: VUE_WEB_URL.blogpicUrl,
				init: {
          selector:'bb',
					language_url: '../../../tinymce/zh_CN.js', // vue-cli 2.x 使用这个
					language: 'zh_CN',
					skin_url: '../../../tinymce/skins/lightgray', // vue-cli 2.x 使用这个
					height: 300,
					plugins: 'link lists image code table colorpicker textcolor wordcount contextmenu charmap print',
					toolbar: 'styleselect bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat charmap | print',
					branding: false,
					images_upload_base_path:this.picUrl,
					// 图片本地上传方法  点击上传后执行的事件
					images_upload_handler: (blobInfo, success, failure) => {
						this.handleImgUpload(blobInfo, success, failure)
					}

				}
			}
		},

		mounted() {
			// 在 mounted 中也需要初始化一次
			tinymce.init({})
			this.tinymceHtml = '';
      this.tinymceHtml = this.checkcon
		},
		watch: {
			tinymceHtml(tinymceHtml) {

				this.$emit("Tinymce", {
					'tinymcedata': tinymceHtml
				});
			},
			checkcon(checkcon) {
				this.tinymceHtml = checkcon
			}
		},

		methods: {

			//version/06d7e2d4-fff3-447f-bb19-30a38cd762d2.jpg
			delFile(ID){
				let paramas={
					id:ID
				}
				/* delfile(paramas).then((res)=>{
					console.log(res)
				}) */

			},

			// 上传本地 图片执行事件
			handleImgUpload(blobInfo, success, failure) {

				let formdata = new FormData()
				// append 方法中的第一个参数就是 我们要上传文件 在后台接收的文件名
				// 这个值要根据后台来定义
				// 第二个参数是我们上传的文件
				formdata.append('file', blobInfo.blob())
				//success('')//
				/* uploadfile(formdata).then((res) => {
					if(res.errorCode == 0) {

						success(this.picUrl + res.data);
						console.log(res.data)
					} else {
						this.$message.error(res.msg);
					}
				}).catch((error) => {
					this.$message.error('添加失败！');
				}) */

				// axios 定义上传方法
				/*axios({
					method: "post", // post方法
					url: this.uploadUrl, // 请求上传图片服务器的路径
					headers: {
						// 配置headers请求头
						Authorization: localStorage.getItem('logintoken'), // token认证，看后台
						'Content-Type': 'application/x-www-form-urlencoded', // 采用表单上传的方式，看后台如何接受
					},
					data: formdata // 请求数据formdata
				}).then(res => {
					if(res.data.code != 200) {
						// 上传失败执行此方法，将失败信息填入参数中
						failure('HTTP Error: ' + res.msg);
						return
					}
					// 上传成功之后，将对应完整的图片路径拼接在success的参数中
					success(this.picUrl + res.data.fileUrl);
				})*/
			}
		},

	}
</script>
<style scoped>

</style>
