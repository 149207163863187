<template>
  <div class="container">
    <div class="query-title">

      <div class="demo-input-suffix">

        <p class="titname">工厂管理</p>

        <el-button type="primary" class="tit-button" @click="addBtn">新 增</el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table highlight-current-row :header-cell-style="{ background: 'rgba(113,183,255,0.4)', color: '#606266', }"
        :data="tabledata" :height="tableHeight" border style="width: 100%">
        <el-table-column type="index" align='center' width="55" label="序号"></el-table-column>
        <el-table-column prop="adress" label="地区" align="center"></el-table-column>
        <el-table-column prop="name" label="工厂名称" align="center"></el-table-column>
        <el-table-column prop="gcgm" label="工厂规模" align="center"></el-table-column>
        <el-table-column prop="gchj" label="工厂环境" align="center"></el-table-column>
        <el-table-column prop="gclx" label="工厂类型" align="center"></el-table-column>
        <!-- <el-table-column prop="gwjs" label="岗位介绍" align="center"></el-table-column>
        <el-table-column prop="xzdy" label="薪资待遇" align="center"></el-table-column> -->
        <el-table-column prop="qyxz" label="企业性质" align="center"></el-table-column>
        <el-table-column prop="salary" label="薪资" align="center"></el-table-column>
        <el-table-column prop="star" label="星级" align="center"></el-table-column>
        <el-table-column prop="zmgz" label="招募工种" align="center"></el-table-column>
        <el-table-column prop="zwxz" label="职位薪资" align="center"></el-table-column>
        <el-table-column prop="" label="工厂图片" align="center">
          <template slot-scope="scope">
          	<el-image class="imgBg" :src="phimgUrurl(scope.row.photoUrl)" :preview-src-list="phimgUrlist(scope.row.photoUrl)">
          		<div slot="error" class="image-slot">
          			<!--@contextmenu.prevent="shows" -->
          			<el-tooltip class="item" effect="dark" :content="scope.row.photoUrl" placement="top">
          				<p class="img-errors">加载失败</p>
          			</el-tooltip>
          		</div>
          	</el-image>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="时间" align="center"></el-table-column>
        <el-table-column prop="groupType" label="群类型 " align="center"></el-table-column>
        <el-table-column prop="" label="操作" width="110" align="center">
          <template slot-scope="scope">
            <el-button class="tabBnt" type="text" @click="edit(scope.row, scope.$index)">编辑</el-button>
            <el-button class="tabBnt" type="text" @click="deletes(scope.row, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Dialog v-if="dialogVisible == true" :title="title" :dialogdata="dialogData" @getcancel="Getcancel"
      @addetermine="addeteRmine"></Dialog>
    <div class="fye">
      <pagintion :ToTal="total" :pageNums="pagenums" @dsydata="DsyData" @dstdata="DstData" />
    </div>
  </div>
</template>

<script>
  import Dialog from "./dialogPopup.vue";
  import pagintion from "@/components/pagintion/pagintion.vue";
  import {gfactorylist,gfactorydelete} from "@/api/api";
  export default {
    name: "factorymanagement",
    components: {
      Dialog,
      pagintion,
    },
    props: {},
    data() {
      return {
        specimenname: "",
        tabledata: [],
        tableHeight: window.innerHeight - 235,
        dialogData: [],
        pageNum: 1,
        pageSize: 20,
        total: null,
        pagenums: null,
        dialogVisible: false,
        title: "",
      };
    },
    computed: {},
    watch: {},
    created() {
      window.addEventListener('resize', this.getHeight)
    },
    destroyed() {
      window.removeEventListener('resize', this.getHeight)
    },
    beforeDestroy() {},
    mounted() {

      this.Datalist();
    },
    methods: {
      phimgUrurl(url){
        return url.split(',')[0]
      },
      phimgUrlist(url){
        return url.split(',')
      },
      Backbtn() {
        this.$router.go(-1)
      },
      getHeight() {
        this.tableHeight = window.innerHeight - 235;
      },

      addBtn() {
        this.dialogData = [];
        this.dialogVisible = true;
        this.title = "新增";
      },
      Getcancel() {
        this.dialogVisible = false;
      },
      addeteRmine() {
        this.dialogVisible = false;
        this.Datalist();
      },
      DsyData(data) {
        this.pagenums = data.dsy;
        this.pageNum = data.dsy;
        this.Datalist()
      },
      DstData(data) {
        this.pageSize = data.dst;
        this.Datalist()
      },

      edit(row, index) {
        this.dialogVisible = true;
        this.dialogData = [row, "edit"];
        this.title = "编辑";
      },
      deletes(row) {

        this.$confirm("此操作将永久删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = [row.id]

            gfactorydelete(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("删除失败！");
            });
          }
        }).catch(() => {});

      },

      Datalist() {
        let params = {
          adress: "",//地区
          gchj:'',//条件
          gclx:'',//工厂
          name:"",//名称
          zmgz:'',//工种
          size:this.pageSize,
          current:this.pageNum,
        }
        gfactorylist(params).then((res) => {
          this.tabledata = res.data.list;
          //this.tabledata = res.data;
          this.total = Number(res.data.total);
        }).catch((error) => {
          this.$message.error("获取数据失败！");
        });
      },
    },
  };
</script>

<style scoped>

</style>
