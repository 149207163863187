<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px" class="demo-rulesFrom"
      style="height: 100%">
      <el-dialog v-dialogDrag :title="title" :visible.sync="dialogVisible" :before-close="handleClose"
        :close-on-press-escape="false" :close-on-click-modal="false">
        <el-row>
          <el-col :span="12">
            <el-form-item type="type" label="工厂地址-省" prop="province" class="frombr">
              <el-select :disabled="disableds" v-model="ruleForm.province" placeholder="省" class="selectClass"
                @change="provinceChange">
                <el-option v-for="(item,i) in provincelist" :key="i" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="工厂地址-市" prop="city" class="frombr">
              <el-select :disabled="disableds" clearable v-model="ruleForm.city" placeholder="市" class="selectClass"
                @change="cityChange">
                <el-option v-for="(item,i) in citylist" :key="i" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="工厂地址-县" prop="county" class="frombr">
              <el-select :disabled="disableds" clearable v-model="ruleForm.county" placeholder="县" class="selectClass">
                <el-option v-for="(item,i) in countylist" :key="i" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="工厂名称" prop="name">
              <el-input :disabled="disableds" v-model.trim="ruleForm.name" placeholder="名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="工厂规模" prop="gcgm">
              <el-select :disabled="disableds" v-model="ruleForm.gcgm" placeholder="工厂规模" class="selectClass">
                <el-option v-for="(item,i) in gcgmlist" :key="i" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>


          <el-col :span="12">
            <el-form-item type="type" label="工厂环境" prop="gchj">
              <el-input :disabled="disableds" v-model.trim="ruleForm.gchj" placeholder="工厂环境" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="工厂类型" prop="gclx">
              <!-- <el-select :disabled="disableds" v-model="ruleForm.gclx" placeholder="工厂类型" class="selectClass">
                <el-option v-for="(item,i) in gclxlist" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select> -->
              <el-input :disabled="disableds" v-model.trim="ruleForm.gclx" placeholder="工厂类型" />
            </el-form-item>
          </el-col>


          <el-col :span="12">
            <el-form-item type="type" label="企业性质" prop="qyxz">
              <el-input :disabled="disableds" v-model.trim="ruleForm.qyxz" placeholder="企业性质" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="薪资" prop="salary">
              <el-input :disabled="disableds" v-model.trim="ruleForm.salary" placeholder="薪资" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="星级" prop="star">
              <el-input :disabled="disableds" v-model.trim="ruleForm.star" placeholder="例如:四星就输入 4" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="标签" prop="label">
              <el-input :disabled="disableds" v-model.trim="ruleForm.label" placeholder="例如:20-40岁,包吃住(英文逗号隔开)" />
            </el-form-item>
          </el-col>


          <el-col :span="12">
            <el-form-item type="type" label="招募工种" prop="zmgz">
              <el-input :disabled="disableds" v-model.trim="ruleForm.zmgz" placeholder="例如:叉车工,焊工(英文逗号隔开)" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="职位薪资" prop="zwxz">
              <el-input :disabled="disableds" v-model.trim="ruleForm.zwxz" placeholder="职位薪资" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="岗位介绍" prop="zwxz">
              <el-button v-if="!disableds" type="primary" @click="gejsbtn" class="dialog-btn">添加岗位介绍</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item type="type" label="薪资待遇" prop="zwxz">
              <el-button v-if="!disableds" type="primary" @click="xzdybtn" class="dialog-btn">添加薪资待遇</el-button>
            </el-form-item>
            <!--  -->
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item type="type" label="薪资待遇" prop="xzdy">
              <el-input :disabled="disableds" v-model.trim="ruleForm.xzdy" type="textarea" :rows="3"
                placeholder="薪资待遇" />
            </el-form-item>
          </el-col> -->

        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item type="type" label="工厂图片" prop="">
              <el-image v-for="(item,i) in imgUrlurl" :key="i" class="HximgBg"
                v-if="dialogState=='edit'&&imgUrl.length==0" :src="item" :preview-src-list="[item]" :z-index="4000">
              </el-image><!-- accept=".jpg,.png"  -->
              <el-upload ref="Epupload" v-if="!disableds" action="" :on-preview="imgUrlPreview" :auto-upload="false"
                list-type="picture-card" :on-change="Epfilechange" :on-remove="EpleRemove">
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
              </el-upload>
              <el-image v-if="imgDialogVisible" :src="dialogImageUrl" :preview-src-list="[dialogImageUrl]"
                :z-index="4000"></el-image>
            </el-form-item>
          </el-col>
        </el-row>

        <span slot="footer" class="dialog-footer">
          <el-button v-if="!disableds" type="primary" @click="deteRmine('ruleForm')">确 定</el-button>
          <el-button @click="cancel">关 闭</el-button>
        </span>

        <transition name="el-fade-in-linear">
          <loading v-if="btnDisableds"></loading>
        </transition>



        <gwjspage v-if="gejsshow" @gejsshowdata='gejsshowdata' @gejsshowdataClose="gejsshowdataClose" :gejsshowval="ruleForm.gwjs"></gwjspage>

        <xzdypage v-if="xzdyshow" @xzdyshowdata='xzdyshowdata' @xzdyshowdataClose="xzdyshowdataClose" :xzdyshowval="ruleForm.xzdy"></xzdypage>


      </el-dialog>
    </el-form>
  </div>
</template>

<script>
  import {
    gfactorysave,
    gfactoryupdate,
    uploadstatic
  } from "@/api/api";

  import gwjspage from './gwjspage.vue'
  import xzdypage from './xzdypage.vue'
  import loading from '@/components/Loading/loading.vue';
  import cityData from '@/common/js/city_code.json';

  export default {
    name: "",
    components: {
      loading,
      gwjspage,
      xzdypage

    },
    props: {
      dialogdata: Array,
      title: String,
    },
    data() {
      return {
        gejsshow: false,
        xzdyshow:false,
        btnDisableds: false,
        dialogVisible: true,
        disableds: false,


        row: "",
        dialogState: "",
        ruleForm: {
          province: '',
          city: '',
          county: "",
          gcgm: "",
          name: "",
          gchj: '',
          gclx: '',
          gwjs: "",
          photoUrl: "", //缩略图地址 多个,隔开
          qyxz: '',
          salary: "",
          star: "",
          xzdy: "",
          zmgz: "",
          zwxz: '',
          adress: "",
          label: '',



        },
        rules: {
          gcgm: [{
            required: true,
            message: "请输入必填项",
            trigger: "change",
          }, ],
          name: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          gclx: [{
            required: true,
            message: "请输入必填项",
            trigger: "change",
          }, ],
          gchj: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          gwjs: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          qyxz: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          salary: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          star: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          xzdy: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          zmgz: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          zwxz: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          province: [{
            required: true,
            message: "请输入必填项",
            trigger: "change",
          }, ],
          label: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
        },
        provincelist: [],
        citylist: [],
        countylist: [],
        gcgmlist: [{
            name: "0~30",
            id: 0
          },
          {
            name: "30~50",
            id: 1
          },
          {
            name: "50~100",
            id: 3
          },
        ], //
        gclxlist: [

        ],
        dialogImageUrl: "",
        imgDialogVisible: false,
        imgUrl: [],
        imgUrlurl: [],
        hideUpload: false,

      };
    },
    computed: {},
    watch: {},
    created() {},
    beforeDestroy() {},
    mounted() {
      this.provincelist = cityData;
      if (this.dialogdata.length == 2) {
        this.dialogState = this.dialogdata[1];
        this.row = this.dialogdata[0];
        this.ruleForm.province = this.row.adress.split(' ')[0]
        this.ruleForm.city = this.row.adress.split(' ')[1]
        this.ruleForm.county = this.row.adress.split(' ')[2]
        this.ruleForm.gcgm = this.row.gcgm;
        this.ruleForm.name = this.row.name;
        this.ruleForm.gchj = this.row.gchj;
        this.ruleForm.gclx = this.row.gclx;
        this.ruleForm.gwjs = this.row.gwjs;
        this.checkcon = this.row.gwjs

        this.ruleForm.photoUrl = this.row.photoUrl;
        this.ruleForm.label = this.row.label;
        this.imgUrlurl = this.row.photoUrl.split(',')
        this.ruleForm.qyxz = this.row.qyxz;
        this.ruleForm.salary = this.row.salary;
        this.ruleForm.star = this.row.star;
        this.ruleForm.xzdy = this.row.xzdy;
        this.ruleForm.zmgz = this.row.zmgz;
        this.ruleForm.zwxz = this.row.zwxz;
      }
    },
    methods: {
      xzdyshowdataClose(){
        this.xzdyshow = false;
      },
      xzdyshowdata(data){
        this.ruleForm.xzdy = data;
        this.xzdyshow = false;
      },
      xzdybtn(){
        this.xzdyshow = true;
      },
      gejsbtn() {
        this.gejsshow = true;
      },
      gejsshowdataClose(){
        this.gejsshow = false;
      },
      gejsshowdata(data) {
        this.ruleForm.gwjs = data;
        this.gejsshow = false;
      },
      imgUrlPreview(file) {
        this.dialogImageUrl = file.url;
        this.imgDialogVisible = true;
      },
      EpleRemove(file, fileList) { // 删除文件

        //this.imgUrl = []
      },
      Epfilechange(file, list) {
        console.log(file)
        if (file.raw.name.split('.')[file.raw.name.split('.').length - 1] == 'png' || file.raw.name.split('.')[file.raw
            .name.split('.').length - 1] == 'js') {
          this.imgUrl = list
          /* if (list.length > 1) {
            list.splice(0, 1);
          } */
          console.log(this.imgUrl)
          let formData = new FormData();
          formData.append('file', this.imgUrl[0])
          /* uploadstatic(formData).then((res) => {
            if (res.status == 200) {
              this.btnDisableds = false;
              this.imgUrlurl = res.data;
            } else {
              this.btnDisableds = false;
              this.$message.error(res.msg);
            }
          }).catch(() => {
            this.btnDisableds = false;
            this.$message.error('图片上传失败失败！');
          }); */
        } else {
          this.$message.error('上传头像图片只能是 png/jpg 格式!');
          this.$refs.Epupload.clearFiles()
          return;
        }
      },
      provinceChange(val) {
        this.ruleForm.city = ''
        this.ruleForm.county = ''
        for (let i = 0; i < this.provincelist.length; i++) {
          if (val == this.provincelist[i].name) {
            this.citylist = this.provincelist[i].city
          }
        }


      },
      cityChange(val) {
        this.ruleForm.county = '';

        for (let i = 0; i < this.citylist.length; i++) {
          if (val == this.citylist[i].name) {
            this.countylist = this.citylist[i].area
          }
        }
      },

      saveData() {

        if (this.dialogState != "edit") {
          let params = {
            adress: this.ruleForm.province + ' ' + this.ruleForm.city + ' ' + this.ruleForm.county,
            gcgm: this.ruleForm.gcgm,
            name: this.ruleForm.name,
            gchj: this.ruleForm.gchj,
            gclx: this.ruleForm.gclx,
            gwjs: this.ruleForm.gwjs,
            photoUrl: this.ruleForm.photoUrl,
            qyxz: this.ruleForm.qyxz,
            salary: this.ruleForm.salary,
            star: this.ruleForm.star,
            xzdy: this.ruleForm.xzdy,
            zmgz: this.ruleForm.zmgz,
            zwxz: this.ruleForm.zwxz,
            label: this.ruleForm.label,

          };
          gfactorysave(params).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "添加成功！",
                type: "success",
              });
              this.$emit("addetermine", {});
            } else {
              this.$message.error(res.msg);
            }
          }).catch((error) => {
            this.$message.error("新增失败！");
          });
        } else {
          let params = {
            adress: this.ruleForm.province + ' ' + this.ruleForm.city + ' ' + this.ruleForm.county,
            gcgm: this.ruleForm.gcgm,
            name: this.ruleForm.name,
            gchj: this.ruleForm.gchj,
            gclx: this.ruleForm.gclx,
            gwjs: this.ruleForm.gwjs,
            photoUrl: this.ruleForm.photoUrl,
            qyxz: this.ruleForm.qyxz,
            salary: this.ruleForm.salary,
            star: this.ruleForm.star,
            xzdy: this.ruleForm.xzdy,
            zmgz: this.ruleForm.zmgz,
            zwxz: this.ruleForm.zwxz,
            label: this.ruleForm.label,
            id: this.row.id,
          };
          gfactoryupdate(params).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "编辑成功！",
                type: "success",
              });
              this.$emit("addetermine", {});
            } else {
              this.$message.error(res.msg);
            }
          }).catch((error) => {
            this.$message.error("编辑失败！");
          });
        }
      },

      deteRmine(fromName) {
        this.$refs[fromName].validate((valid) => {
          if (valid) {
            if (this.imgUrl != 0) {
              if (this.dialogState == "edit") {
                this.ruleForm.photoUrl = ''
              }
              for (let i = 0; i < this.imgUrl.length; i++) {
                if (i != this.imgUrl.length - 1) {
                  let formData = new FormData();
                  formData.append('file', this.imgUrl[i].raw)
                  uploadstatic(formData).then((res) => {
                    if (res.status == 200) {
                      this.ruleForm.photoUrl += res.data + ','
                      /* this.btnDisableds = false;
                      this.imgUrlurl = res.data; */
                    } else {
                      /* this.btnDisableds = false;
                      this.$message.error(res.msg); */
                    }
                  }).catch(() => {
                    this.btnDisableds = false;
                    this.$message.error('图片上传失败失败！');
                  });
                } else {
                  let formData = new FormData();
                  formData.append('file', this.imgUrl[i].raw)
                  uploadstatic(formData).then((res) => {
                    if (res.status == 200) {
                      this.ruleForm.photoUrl += res.data
                      this.btnDisableds = false;
                      this.saveData();

                    } else {
                      this.btnDisableds = false;
                      this.$message.error(res.msg);
                    }
                  }).catch(() => {
                    this.btnDisableds = false;
                    this.$message.error('图片上传失败失败！');
                  });
                }
              }
            } else {
              this.saveData()
            }

          } else {
            return false;
          }
        });
      },
      cancel() {
        this.dialogVisible = false;
        this.$emit("getcancel");
      },

      handleClose() {
        this.dialogVisible = false;
        this.$emit("getcancel");
      },
    },
  };
</script>

<style scoped>
  .selectClass {
    width: 100%;
  }

  ::v-deep .el-dialog {
    width: 900px;
    border-radius: 8px;
    overflow: hidden;
  }

  ::v-deep .el-dialog__body {
    max-height: 500px;
    overflow: auto;
  }

  .rowTops {
    position: relative;
  }

  .rowTopa {
    position: absolute;
    left: 0px;
    top: 40px;
    right: 0px;
    margin: auto;
    max-height: 300px;
    background: #fff;
    border: 1px solid #e3e3e3;
    z-index: 100;
    border-radius: 5px;
    overflow: auto;
    padding-bottom: 10px;
  }

  .iptclose {
    position: absolute;
    right: 10px;
    top: 0px;
    color: #606266;
    font-size: 16px;
    line-height: 40px;
    z-index: 1000;
    transition: color 0.15s linear;
  }

  .iptclose:hover {
    color: #5cb6ff !important;
  }

  .HximgBg {
    width: 126px;
    height: 126px;
    float: left;
    margin-right: 10px;
    border-radius: 6px;
  }
</style>
